import React from "react"



export default ({ data }) =>
        <div id="about"  style={{ height: 500 }}>
            <h1>
                Daniel Jakobian
            </h1>
            <p>I'm <b title={Date()}>currently,</b><br/></p>
            <ul>
              
                <li>
                    <p><strike>working in Web & App Development</strike>, with an interest in the full stack and appreciation for good UX/UI Design</p>
                </li>
              <li>trying to tap into the source in order to deliver creative sustenance</li>
                <li>
                    <p>passionate about Ethereum and the Web3 ecosystem</p>
                </li>
                <li>
                    <p>creating <a href="https://superrare.co/danieljakobian/creations" target="_blank" rel="noopener noreferrer">shitty (awesome) comics, pixelart and generative art</a>, instead of doing real work</p>
                </li>
                <li><p>having aspirations: <i className="fa fa-check" aria-hidden="true"></i></p></li>
            </ul>
        </div>
